document.addEventListener('DOMContentLoaded', () => {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });
    }

    const dropdown = document.querySelector('.dropdown');

    if (typeof dropdown !== 'undefined' && dropdown !== null && dropdown.length > 0) {
        dropdown.addEventListener('click', function (event, elm) {
            event.stopPropagation();
            dropdown.classList.toggle('is-active');
        });
    }

    const $navbarScrollAnchors = Array.prototype.slice.call(document.querySelectorAll('.navbar-scroll a'), 0);

    if ($navbarScrollAnchors.length > 0) {
        $navbarScrollAnchors.forEach(el => {
            el.addEventListener('click', (e) => {
                if (e.classList.contains('ignore') === false) {
                    e.preventDefault();

                    const $target = document.querySelector(el.getAttribute('href'));

                    $target.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        });
    }
});